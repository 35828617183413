import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Exames from '@monorepo-amais/commons/components/exames/Exames'
import imgixUrlOptimizerAuto from '@monorepo-amais/commons/utils/imgixUrlOptimizerAuto'

const ExamesPage = ({ data, scrollTo }) => (
	<Layout>
		<SEO
			title={data.cosmicjsInformacoesEstaticas.metadata.seo.titulo}
			description={data.cosmicjsInformacoesEstaticas.metadata.seo.descricao}
			image={imgixUrlOptimizerAuto(data.cosmicjsInformacoesEstaticas.metadata.seo.imagem.imgix_url)}
		/>

		<Exames data={data} />
	</Layout>
)

export const pageQuery = graphql`
	query {
		cosmicjsInformacoesEstaticas(title: { eq: "Lista Exames" }) {
			metadata {
				seo {
					descricao
					titulo
					imagem {
						imgix_url
						url
					}
				}
			}
		}

		allTopExames {
			edges {
				node {
					id: idLegado
					name: nome
					slug
				}
			}
		}
	}
`

export default ExamesPage
